import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchAllProjects, fetchAllStudents } from "helpers/airtable.helper";
import PageLoading from "components/PageLoading";

const ProjectContext = createContext();
// Custom hook to access the ProjectContext
export const useProject = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [students, setStudents] = useState([]);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      const projectsResponse = await fetchAllProjects();
      setProjects(projectsResponse);
      const studentsResponse = await fetchAllStudents();
      setStudents(studentsResponse);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const value = { projects, students, error, fetchAllProjects };

  if (loading) {
    return <PageLoading />;
  }
  return (
    <ProjectContext.Provider value={value}>
      {!loading && children}
    </ProjectContext.Provider>
  );
};
