import React, { createContext, useContext, useState, useEffect } from "react";
import { firebaseAuth } from "config/firebase";
import {
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut
} from "firebase/auth";
import { users_table, students_table } from "config/airtable-config";
import PageLoading from "components/PageLoading";
import { fetchAllUsers } from "helpers/airtable.helper";

const AuthContext = createContext();
// Custom hook to access the AuthContext
export const useAuth = () => useContext(AuthContext);

// Provider component
export const AuthProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersResponse = await fetchAllUsers();
        setUsers(usersResponse);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, []);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      setFirebaseUser(user);
      if (user) {
        try {
          const userData = await fetchUserDataByEmail(user.email);
          setCurrentUser(userData);
          setError("");
        } catch (error) {
          setError(
            error.message || "An error occurred while fetching user data."
          );
        }
      } else {
        setCurrentUser(null); // Clear currentUser if no Firebase user is authenticated
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const handleError = (error, defaultMessage) => {
    console.error(error);
    setError(error.message || defaultMessage);
  };

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(firebaseAuth, email, password);
      setError("");
    } catch (error) {
      handleError(
        error,
        "An error occurred while signing in. Please try again."
      );
    }
  };

  const logout = async () => {
    try {
      await signOut(firebaseAuth);
      setError("");
    } catch (error) {
      handleError(error, "An error occurred while signing out.");
    }
  };

  // Function to send sign-in link to email
  const sendLoginLinkToEmail = async (email) => {
    try {
      if (!email) {
        setError("Please enter a valid email address.");
        return;
      }
      const actionCodeSettings = {
        url: `${process.env.REACT_APP_BASE_URL}/auth/completeSignIn`,
        handleCodeInApp: true
      };
      await sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings);
      setError("");
    } catch (error) {
      handleError(
        error,
        "An error occurred while sending the sign-in link. Please try again."
      );
    }
  };

  // Function to complete sign-in with email link
  const completeSignInWithEmailLink = async (email, emailLink) => {
    try {
      await signInWithEmailLink(firebaseAuth, email, emailLink);
      setError(""); // Clear any previous errors
    } catch (error) {
      handleError(
        error,
        "An error occurred while completing sign-in with email link."
      );
    }
  };

  // Function to fetch user data by email from Airtable
  const fetchUserDataByEmail = async (email) => {
    try {
      // Combine search in 'users' table for 'email_primary' and 'email_backup'
      const filterFormula = `OR({email_primary} = '${email}', {email_backup} = '${email}')`;
      const user_records = await users_table
        .select({ filterByFormula: filterFormula })
        .firstPage();

      // If multiple accounts are found with the same email address, throw an error
      if (user_records.length > 1) {
        throw new Error(
          "Multiple accounts found with the same email address. Please contact support at eventcoordinator@most.org to resolve this issue."
        );
      } else if (user_records.length === 1) {
        return { id: user_records[0].id, ...user_records[0].fields };
      }

      // If not found in 'users' table, search in 'students' table by 'emergency_email'
      const student_records = await students_table
        .select({ filterByFormula: `{emergency_email} = '${email}'` })
        .all();

      if (student_records.length > 0) {
        // Extract student IDs from records into an array
        const fk_student_IDs = student_records.map((record) => record.id);
        // Extract unique project IDs from all student records
        const fk_project_IDs = [
          ...new Set(
            student_records.flatMap((record) => record.fields.projects || [])
          )
        ];

        return {
          user_role: "emergency_contact",
          fk_student_IDs,
          fk_project_IDs
        };
      }

      // If no records found across tables
      throw new Error("No user found with the provided email.");
    } catch (error) {
      console.error("Error fetching user data: ", error.message);
      setError(error.message || "An error occurred while fetching user data.");
    }
  };

  const value = {
    users,
    firebaseUser,
    currentUser,
    error,
    login,
    logout,
    sendLoginLinkToEmail,
    completeSignInWithEmailLink,
    fetchUserDataByEmail
  };

  if (loading) {
    return <PageLoading />;
  }
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
