import React, { useEffect, useState, useRef } from "react";
import { useAwards } from "contexts/AwardsContext";
import { useLocation } from "react-router-dom";

// Import MUI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";

// Import assets
import cnysefLogo from "assets/img/cnysef.svg";
import AwardEntry from "./awards/AwardEntry";
import { useTheme } from "@mui/material/styles";

const WebAwardsPage = () => {
  const { awards } = useAwards();
  const theme = useTheme();

  // Use useLocation hook to access current URL
  const location = useLocation();
  // URLSearchParams to parse the query parameter
  const searchParams = new URLSearchParams(location.search);
  const showDetails = searchParams.get("details") === "true";

  // Function to handle copy email button
  const [clickedButtons, setClickedButtons] = useState({});
  const timeoutsRef = useRef({});

  const handleButtonClick = (awardID, uniqueEmails) => {
    navigator.clipboard.writeText(uniqueEmails);
    // Set the button as clicked in the state
    setClickedButtons((prevClickedButtons) => ({
      ...prevClickedButtons,
      [awardID]: true,
    }));

    // Clear any existing timeout for this button to avoid multiple timeouts running simultaneously
    if (timeoutsRef.current[awardID]) {
      clearTimeout(timeoutsRef.current[awardID]);
    }

    // Set a timeout to revert the button text after 5 seconds
    timeoutsRef.current[awardID] = setTimeout(() => {
      setClickedButtons((prevClickedButtons) => ({
        ...prevClickedButtons,
        [awardID]: false, // Revert the button's clicked state
      }));

      // Clear the timeout from timeoutsRef after it's executed
      delete timeoutsRef.current[awardID];
    }, 5000); // 5000 milliseconds = 5 seconds
  };

  useEffect(() => {
    const currentTimeouts = timeoutsRef.current;
    return () => {
      // Clear all timeouts to prevent memory leaks
      Object.values(currentTimeouts).forEach(clearTimeout);
    };
  }, []);

  const sections = [
    { name: "ISEF Awards", description: "These awards are provided by ISEF." },
    {
      name: "Regional Awards",
      description:
        "Regional awards are provided by Central New York organizations, colleges, and corporations.",
    },
    { name: "Medallions", description: "" },
    { name: "Advancements/Grand Prizes", description: "" },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#E5E4E2",
        width: "100vw",
        m: 0,
        py: 4,
        boxSizing: "border-box",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "12%",
            [theme.breakpoints.down("md")]: {
              width: "25%",
            },
          }}
        >
          <img
            src={cnysefLogo}
            alt="CNYSEF Logo"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Box>
        <Typography
          variant="h5"
          textAlign="center"
          fontWeight={500}
          fontSize="2em"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "1.5em",
            },
          }}
        >
          2025 CNY Science & Engineering Fair Awards
        </Typography>
        {sections.map((section, index) => (
          <Box
            key={`award-section-${index}`}
            sx={{
              width: "100%",
              my: 3,
              backgroundColor: "white",
              padding: 3,
              borderRadius: 2,
              boxSizing: "border-box",
              [theme.breakpoints.down("sm")]: {
                fontSize: ".75em",
              },
            }}
          >
            <Box
              sx={{
                borderBottom: "1px dashed #775DA7",
                pb: 2,
                mb: 2,
                boxSizing: "border-box",
              }}
            >
              <Typography
                variant="h4"
                fontSize="2.5em"
                fontWeight={600}
                textAlign="center"
                textTransform="uppercase"
                color="#775DA7"
              >
                {section.name}
              </Typography>
              <Typography
                variant="body1"
                color="#3E3D3D"
                fontWeight={400}
                textAlign="center"
                fontSize="1.25em"
              >
                {section.description}
              </Typography>
            </Box>
            {awards
              .filter((award) => award.award_type === section.name)
              .sort((a, b) => a.award_order - b.award_order)
              .map((award) => {
                if (!award.project && !award.registrant) return null;
                const uniqueEmailsSet = new Set(award?.rollup_project_emails);
                const uniqueEmails = Array.from(uniqueEmailsSet).join(",");
                return (
                  <Box
                    key={award.id}
                    sx={{
                      width: "100%",
                      mb: 3,
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={500}
                      color="#775DA7"
                      my={0.75}
                      sx={{
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "1.75em",
                        },
                      }}
                    >
                      {award.Name}
                    </Typography>
                    {award.project &&
                      award.project.length > 0 &&
                      award.project.map((projectID) => (
                        <AwardEntry
                          key={`${award.id}-${projectID}`}
                          projectID={projectID}
                          details={showDetails}
                        />
                      ))}
                    {award.registrant &&
                      award.registrant.length > 0 &&
                      award.registrant.map((registrantID) => (
                        <AwardEntry
                          key={`${award.id}-${registrantID}`}
                          userID={registrantID}
                        />
                      ))}

                    {showDetails && (
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ ml: 2 }}
                        onClick={() => {
                          handleButtonClick(award.id, uniqueEmails);
                        }}
                        disabled={clickedButtons[award.id]}
                        endIcon={clickedButtons[award.id] && <CheckIcon />}
                      >
                        {clickedButtons[award.id]
                          ? "Emails copied to your clipboard!"
                          : "Copy All Emails"}
                      </Button>
                    )}
                  </Box>
                );
              })}
          </Box>
        ))}
      </Container>
    </Box>
  );
};

WebAwardsPage.propTypes = {};

export default WebAwardsPage;
