import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchAllAwards } from "helpers/airtable.helper";
import PageLoading from "components/PageLoading";

const AwardsContext = createContext();
// Custom hook to access the AwardsContext
export const useAwards = () => useContext(AwardsContext);

export const AwardsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [awards, setAwards] = useState([]);
  const [error, setError] = useState("");

  const fetchAwards = async () => {
    try {
      //   const projectsResponse = await fetchAllProjects();
      const awardsResponse = await fetchAllAwards();
      setAwards(awardsResponse);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchAwards();
  }, []);

  const value = { awards, error, fetchAwards };

  if (loading) {
    return <PageLoading />;
  }
  return (
    <AwardsContext.Provider value={value}>
      {!loading && children}
    </AwardsContext.Provider>
  );
};
