// src/routes/routes.js

// Importing libraries
import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

// Lazy-loading components
import NotFoundPage from "pages/NotFoundPage";
import CheckInResultsPage from "pages/CheckInResultsPage";
import WebAwardsPage from "pages/WebAwardsPage";
// const HomePage = lazy(() => import("pages/HomePage"));
const LoginPage = lazy(() => import("pages/LoginPage"));
const ProgramPage = lazy(() => import("pages/ProgramPage"));
// const ProjectsListPage = lazy(() => import("pages/ProjectsListPage"));
// const ProjectDetailPage = lazy(() => import("pages/ProjectDetailsPage"));
const ReportsPage = lazy(() => import("pages/ReportsPage"));
const CheckInPage = lazy(() => import("pages/CheckInPage"));
const AwardsPage = lazy(() => import("pages/AwardsPage"));

// // Authentication check function
// const isAuthenticated = () => {
//     // Implement your authentication logic here
//   };

//   // Protected Route Wrapper
//   const ProtectedRoute = ({ children }) => {
//     return isAuthenticated() ? children : <Navigate to="/auth/login" replace />;
//   };

// Route configuration
export const routesConfig = [
  {
    path: "/",
    element: <Navigate to="/awards" replace />
  },
  {
    path: "/auth/login",
    element: <LoginPage />
    // element: isAuthenticated() ? <Navigate to="/" replace /> : <LoginPage /> // Uncomment this line to redirect to home page if user is already authenticated
  },
  // {
  //   path: "/projects",
  //   element: <ProjectsListPage />
  // },
  // {
  //   path: "/project/:projectID",
  //   element: <ProjectDetailPage />
  // },
  // {
  //   path: "/projects/:projectID",
  //   element: <ProjectDetailPage />
  // },
  {
    path: "/check-in",
    element: <CheckInPage />
  },
  {
    path: "/checkin/",
    element: <CheckInPage />
  },
  {
    path: "/check-in/results",
    element: <CheckInResultsPage />
  },
  {
    path: "/awards",
    element: <WebAwardsPage />
  },
  {
    path: "/awards/presentation",
    element: <AwardsPage />
  },
  {
    path: "/reports",
    element: <ReportsPage />
  },
  {
    path: "/program",
    element: <ProgramPage />
  },
  {
    path: "*",
    element: <NotFoundPage />
  }
];
